import React, { Component } from 'react';
import { Link, withRouter, useHistory } from 'react-router-dom';
import { Collapse, Dropdown } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import './sidebar.css'
class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.user = ''
    this.logout = this.logout.bind(this);
    //  this.handleSubmit = this.handleSubmit.bind(this);
  }
  state = {};

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach(i => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  componentDidMount() {
    console.log(this.props)
  }
  onRouteChanged() {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(this.state).forEach(i => {
      this.setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: '/apps', state: 'appsMenuOpen' },
      { path: '/events', state: 'eventsMenuOpen' },
      { path: '/sponsors', state: 'sponsorsMenuOpen' },
      // {path:'/sponsors/add', state: 'addSponsorsMenuOpen'},
      { path: '/players', state: 'playersMenuOpen' },
      { path: '/announcements', state: 'announcementsMenuOpen' },
      { path: '/users', state: 'usersMenuOpen' },
      { path: '/user-pages', state: 'userPagesMenuOpen' },
      { path: '/aboutval', state: 'aboutvalMenuOpen' },
      { path: '/athletetemplate', state: 'athletetemplateMenuOpen' },
    ];

    dropdownPaths.forEach((obj => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true })
      }
    }));

  }
  logout() {
    localStorage.setItem('user', '')
    window.location.reload();
    //   this.state.history.push('/user-pages/login-1')
  }
  setval() {
    this.setState({user:this.props.dataFromParent})
    //   this.state.history.push('/user-pages/login-1')
  }
  render() {
    console.log("sidebar")
    //this.setval()
    console.log(this.props.dataFromParent)
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <div className="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top">
          <a className="sidebar-brand brand-logo" href="index.html"><img src={require('../../assets/images/logo.8d2895f5.png')} alt="logo" /></a>
          <a className="sidebar-brand brand-logo-mini" href="index.html"><img src={require('../../assets/images/favicon.png')} alt="logo" /></a>
        </div>
        <ul className="nav">
          <li className="nav-item profile">
            <div className="profile-desc">
              <div className="profile-pic">
                <div className="count-indicator">
                  <img className="img-xs rounded-circle " src={require('../../assets/images/faces-clipart/pic-1.png')} alt="profile" />
                  <span className="count bg-success"></span>
                </div>

                {
                  this.props.dataFromParent != undefined ?
                    <div className="profile-name">
                      <h5 className="mb-0 font-weight-normal"><Trans>{this.props.dataFromParent.Username}</Trans></h5>
                      {/* <p><Trans>{this.props.dataFromParent.AthleteRegistrationType}</Trans></p> */}
                      <span><Trans>Next Event</Trans></span>
                    </div>
                    :
                    <div className="profile-name">
                      <h5 className="mb-0 font-weight-normal"><Trans>Admin</Trans></h5>
                      {/* <span><Trans>{this.props.dataFromParent.AthleteRegistrationType}</Trans></span> */}
                      <span><Trans>Next Event</Trans></span>
                    </div>
                }

              </div>
              <Dropdown alignRight>
                <Dropdown.Toggle as='a' className="cursor-pointer no-caret" >
                  <i className="mdi mdi-dots-vertical"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu className="sidebar-dropdown preview-list">
                  {/* <a href="!#" className="dropdown-item preview-item" onClick={evt =>evt.preventDefault()}>
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-dark rounded-circle">
                        <i className="mdi mdi-settings text-primary"></i>
                      </div>
                    </div>
                    <div className="preview-item-content">
                      <p className="preview-subject ellipsis mb-1 text-small"><Trans>Account settings</Trans></p>
                    </div>
                  </a> */}
                  {/* <div className="dropdown-divider"></div> */}
                  <a href="!#" className="dropdown-item preview-item" onClick={evt => evt.preventDefault()}>
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-dark rounded-circle">
                        <i className="mdi mdi-onepassword  text-info"></i>
                      </div>
                    </div>
                    <div className="preview-item-content">
                      <Link to="/adminchangepassword/adminchangepassword">
                        <p className="preview-subject ellipsis mb-1 text-small logoutBtn"><Trans>Change Password</Trans></p>
                      </Link>
                    </div>
                  </a>
                  {/* <div className="dropdown-divider"></div> */}
                  <a href="!#" className="dropdown-item preview-item" onClick={evt => evt.preventDefault()}>
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-dark rounded-circle">
                        <i className="mdi mdi-calendar-today text-success"></i>
                      </div>
                    </div>
                    <div className="preview-item-content">
                      <button className="preview-subject ellipsis mb-1 text-small logoutBtn" onClick={this.logout}><Trans>Logout</Trans></button>
                    </div>
                  </a>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </li>

          {
            this.props.dataFromParent!= undefined?
            <>
            {
              this.props.dataFromParent.UserType == "Admin"?
              <div>
              <li className="nav-item nav-category">
                <span className="nav-link"><Trans>Admin</Trans></span>
              </li>
              <li className={this.isPathActive('/dashboard') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
                <Link className="nav-link" to="/dashboard">
                  <span className="menu-icon"><i className="mdi mdi-speedometer"></i></span>
                  <span className="menu-title"><Trans>Dashboard</Trans></span>
                </Link>
              </li>
              <li className={this.isPathActive('/events') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
                <div className={this.state.eventsMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('eventsMenuOpen')} data-toggle="collapse">
                  <span className="menu-icon">
                    <i className="mdi mdi-laptop"></i>
                  </span>
                  <span className="menu-title"><Trans>Meetings</Trans></span>
                  <i className="menu-arrow"></i>
                </div>
                <Collapse in={this.state.eventsMenuOpen}>
                  <div>
                    <ul className="nav flex-column sub-menu">
                      <li className="nav-item"> <Link className={this.isPathActive('/events/upcoming') ? 'nav-link active' : 'nav-link'} to="/events/upcoming"><Trans>Upcoming</Trans></Link></li>
                      <li className="nav-item"> <Link className={this.isPathActive('/events/past') ? 'nav-link active' : 'nav-link'} to="/events/past"><Trans>Past Events</Trans></Link></li>
                    </ul>
                  </div>
                </Collapse>
              </li>
              <li className={this.isPathActive('/sponsors') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
          <Link className={this.isPathActive('/sponsors/sponsors') ? 'nav-link active' : 'nav-link'} to="/sponsors/sponsors">
            <span className="menu-icon"><i className="mdi mdi-playlist-play"></i></span>
            <span className="menu-title"><Trans>Sponsors</Trans></span>
          </Link>
        </li>
        <li className={this.isPathActive('/players') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
          <Link className={this.isPathActive('/players/players') ? 'nav-link active' : 'nav-link'} to="/players/players" >
            <span className="menu-icon"><i className="mdi mdi-playlist-play"></i></span>
            <span className="menu-title"><Trans>Athletes</Trans></span>
          </Link>
        </li>
        <li className={this.isPathActive('/users') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
          <div className={this.state.usersMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('usersMenuOpen')} data-toggle="collapse">
            <span className="menu-icon">
              <i className="mdi mdi-chart-bar"></i>
            </span>
            <span className="menu-title"><Trans>App Users</Trans></span>
            <i className="menu-arrow"></i>
          </div>
          <Collapse in={this.state.usersMenuOpen}>
            <div>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={this.isPathActive('/users/users') ? 'nav-link active' : 'nav-link'} to="/users/users"><Trans>Users</Trans></Link></li>
                <li className="nav-item"> <Link className={this.isPathActive('/users/userstransactions') ? 'nav-link active' : 'nav-link'} to="/users/userstransactions"><Trans>Users Transactions</Trans></Link></li>
              </ul>
            </div>
          </Collapse>
        </li>
        <li className={this.isPathActive('/announcements') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
          <Link className={this.isPathActive('/announcements/announcements') ? 'nav-link active' : 'nav-link'} to="/announcements/announcements">
            <span className="menu-icon"><i className="mdi mdi-playlist-play"></i></span>
            <span className="menu-title"><Trans>Announcements</Trans></span>
          </Link>
        </li>
        <li className={this.isPathActive('/aboutval') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
          <Link className={this.isPathActive('/aboutval/aboutval') ? 'nav-link active' : 'nav-link'} to="/aboutval/aboutval">
            <span className="menu-icon"><i className="mdi mdi-playlist-play"></i></span>
            <span className="menu-title"><Trans>About VAL</Trans></span>
          </Link>
        </li>
        {/* <li className={this.isPathActive('/athletetemplate') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
          <Link className={this.isPathActive('/athletetemplate') ? 'nav-link active' : 'nav-link'} to="/athletetemplate">
            <span className="menu-icon"><i className="mdi mdi-playlist-play"></i></span>
            <span className="menu-title"><Trans>Add athlete template</Trans></span>
          </Link>
        </li> */}
            </div>
              :
              null
            }
            </>
              :
              null
          }
          {
            this.props.dataFromParent!= undefined?
            <>
            {
              this.props.dataFromParent.UserType == "Athlete"?
              <div>
 <li className="nav-item nav-category">
            <span className="nav-link"><Trans>Athletes</Trans></span>
          </li>
          {/* Athletes */}
          <li className={this.isPathActive('/athletedashboard') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
            <Link className="nav-link" to="/athletedashboard">
              <span className="menu-icon"><i className="mdi mdi-speedometer"></i></span>
              <span className="menu-title"><Trans>Dashboard</Trans></span>
            </Link>
          </li>
          <li className={this.isPathActive('/athleteachievements') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
            <Link className={this.isPathActive('/athleteachievements/athleteachievements') ? 'nav-link active' : 'nav-link'} to="/athleteachievements/athleteachievements">
              <span className="menu-icon"><i className="mdi mdi-playlist-play"></i></span>
              <span className="menu-title"><Trans>My Achievements</Trans></span>
            </Link>
          </li>
          <li className={this.isPathActive('/athleteeditprofile') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
            <Link className={this.isPathActive('/athleteeditprofile/athleteeditprofile') ? 'nav-link active' : 'nav-link'} to="/athleteeditprofile/athleteeditprofile">
              <span className="menu-icon"><i className="mdi mdi-playlist-play"></i></span>
              <span className="menu-title"><Trans>Edit Profile</Trans></span>
            </Link>
          </li>
          <li className={this.isPathActive('/athletetransactions') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
            <Link className={this.isPathActive('/athletetransactions/athletetransactions') ? 'nav-link active' : 'nav-link'} to="/athletetransactions/athletetransactions">
              <span className="menu-icon"><i className="mdi mdi-playlist-play"></i></span>
              <span className="menu-title"><Trans>My Transactions</Trans></span>
            </Link>
          </li>
          {/* Athletes */}
              </div>
              :null
              }
          </>
          :null
  }

{
            this.props.dataFromParent!= undefined?
            <>
            {
              this.props.dataFromParent.UserType == "Sponsors"?
              <div>
          <li className="nav-item nav-category">
            <span className="nav-link"><Trans>Sponsors</Trans></span>
          </li>
          {/* sponsors */}
          <li className={this.isPathActive('/sponsorsdashboard') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
            <Link className="nav-link" to="/sponsorsdashboard">
              <span className="menu-icon"><i className="mdi mdi-speedometer"></i></span>
              <span className="menu-title"><Trans>Dashboard</Trans></span>
            </Link>
          </li>
          <li className={this.isPathActive('/adtemplate') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
            <Link className={this.isPathActive('/adtemplate/adtemplate') ? 'nav-link active' : 'nav-link'} to="/adtemplate/adtemplate">
              <span className="menu-icon"><i className="mdi mdi-playlist-play"></i></span>
              <span className="menu-title"><Trans>AD Templates</Trans></span>
            </Link>
          </li>
          <li className={this.isPathActive('/add-advertisement') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
            <Link className={this.isPathActive('/add-advertisement') ? 'nav-link active' : 'nav-link'} to="/add-advertisement">
              <span className="menu-icon"><i className="mdi mdi-playlist-play"></i></span>
              <span className="menu-title"><Trans>Add Advertisement</Trans></span>
            </Link>
          </li>
          <li className={this.isPathActive('/myads') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
            <Link className={this.isPathActive('/myads/myads') ? 'nav-link active' : 'nav-link'} to="/myads/myads">
              <span className="menu-icon"><i className="mdi mdi-playlist-play"></i></span>
              <span className="menu-title"><Trans>My ADs</Trans></span>
            </Link>
          </li>
          {/* <li className={this.isPathActive('/addAdvertisement') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
            <Link className={this.isPathActive('/addAdvertisement/addAdvertisement') ? 'nav-link active' : 'nav-link'} to="/addAdvertisement/addAdvertisement">
              <span className="menu-icon"><i className="mdi mdi-playlist-play"></i></span>
              <span className="menu-title"><Trans>Add Advertisement</Trans></span>
            </Link>
          </li> */}
          <li className={this.isPathActive('/sponsorprofileedit') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
            <Link className={this.isPathActive('/sponsorprofileedit/sponsorprofileedit') ? 'nav-link active' : 'nav-link'} to="/sponsorprofileedit/sponsorprofileedit">
              <span className="menu-icon"><i className="mdi mdi-playlist-play"></i></span>
              <span className="menu-title"><Trans>Edit My Profile</Trans></span>
            </Link>
          </li>
          <li className={this.isPathActive('/sponsors') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
          <Link className={this.isPathActive('/sponsors/sponsors') ? 'nav-link active' : 'nav-link'} to="/sponsors/sponsors">
            <span className="menu-icon"><i className="mdi mdi-playlist-play"></i></span>
            <span className="menu-title"><Trans>Sponsors</Trans></span>
          </Link>
        </li>
          {/* sponsors */}
                 </div>
              :null
              }
          </>
          :null
  }

        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {

      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }

}

export default withRouter(Sidebar);