import React, { Component,Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Spinner from '../app/shared/Spinner';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Dashboard = lazy(() => import('./dashboard/Dashboard'));
const Athletedashboard = lazy(() => import('./athletedashboard/Athletedashboard'));
const Athleteevents = lazy(() => import('./athletedashboard/Athleteevents'));
const Sponsorsdashboard = lazy(() => import('./sponsorsdashboard/Sponsorsdashboard'));
const EventDetails = lazy(() => import('./eventDetails/EventDetails'));
const AddEvent = lazy(() => import('./addEvent/AddEvent'));
const AddMeeting = lazy(() => import('./addmeeting/AddMeeting'));
const Upcoming = lazy(() => import('./events/Upcoming'));
const EditProfile = lazy(() => import('./editProfile/EditProfile'));
const Past = lazy(() => import('./events/Past'));
const Sponsors = lazy(() => import('./sponsors/Sponsors'));
const AddSponsor = lazy(() => import('./addSponsor/AddSponsor'));
const AddAdvertisement = lazy(() => import('./addAdvertisement/AddAdvertisement'));
const Players = lazy(() => import('./players/Players'));
const Announcements = lazy(() => import('./announcements/Announcements'));
const Users = lazy(() => import('./users/Users.js'));
const Userstransactions = lazy(() => import('./users/Userstransactions'));
const Aboutval = lazy(() => import('./aboutval/Aboutval'));
const Athleteeditprofile = lazy(() => import('./athleteeditprofile/Athleteeditprofile'));
const Athletetransactions = lazy(() => import('./athletetransactions/Athletetransactions'));
const Athleteachievements = lazy(() => import('./athleteachievements/Athleteachievements'));
const Athletetemplate = lazy(() => import('./athletetemplate/Athletetemplate'));
const Adtemplate = lazy(() => import('./adtemplate/Adtemplate'));
const Sponsorprofileedit = lazy(() => import('./sponsorprofileedit/Sponsorprofileedit'));
const Editevent = lazy(() => import('./EditEvent/EditEvent'));
const Changepassword = lazy(() => import('./changepassword/Changepassword'));
const AdminChangePassword = lazy(() => import('./AdminChangePassword/AdminChangePassword'));
const Myads = lazy(() => import('./myads/Myads'));
const Login = lazy(() => import('./user-pages/Login'));
const Forgotpassword = lazy(() => import('./user-pages/Forgotpassword'));
const Register1 = lazy(() => import('./user-pages/Register'));





class AppRoutes extends Component {
  render () {
    return (
      <Suspense fallback={<Spinner/>}>
        <Switch>
          <Route exact path="/dashboard" component={ Dashboard } />
          <Route path="/user-pages/Forgotpassword" component={ Forgotpassword } />
          <Route exact path="/athletedashboard" component={ Athletedashboard } />
          <Route exact path="/athleteevents" component={ Athleteevents } />
          <Route exact path="/sponsorsdashboard" component={ Sponsorsdashboard } />
          <Route exact path="/athletetemplate" component={ Athletetemplate } />
          <Route path="/events/upcoming" component={ Upcoming } />
          <Route path="/events/details" component={ EventDetails } />
          <Route path="/editevent" component={ Editevent } />
          <Route path="/events/add" component={ AddEvent } />
          <Route path="/Meeting/add" component={ AddMeeting } />
          <Route path="/events/past" component={ Past } />
          <Route path="/sponsors/sponsors" component={ Sponsors } />
          <Route path="/sponsors/add" component={ AddSponsor } />
          <Route path="/advertisements/add" component={ AddAdvertisement } />
          <Route path="/players/players" component={ Players } />
          <Route path="/edit/profile" component={ EditProfile } />
          <Route path="/announcements/announcements" component={ Announcements } />
          <Route path="/users/users" component={ Users } />
          <Route path="/users/userstransactions" component={ Userstransactions } />
          <Route path="/user-pages/login-1" component={ Login } />
          <Route path="/user-pages/register-1" component={ Register1 } />
          <Route path="/aboutval/aboutval" component={ Aboutval } />
          <Route path="/addAdvertisement/addAdvertisement" component={ AddAdvertisement } />
          <Route path="/athleteeditprofile/athleteeditprofile" component={ Athleteeditprofile } />
          <Route path="/athletetransactions/athletetransactions" component={ Athletetransactions } />
          <Route path="/athleteachievements/athleteachievements" component={ Athleteachievements } />
          <Route path="/myads/myads" component={ Myads } />
          <Route path="/adtemplate/adtemplate" component={ Adtemplate } />
          <Route path="/add-advertisement" component={ AddAdvertisement } />
          <Route path="/sponsorprofileedit/sponsorprofileedit" component={ Sponsorprofileedit } /> 
          <Route path="/changepassword/changepassword" component={ Changepassword } />
          <Route path="/adminchangepassword/adminchangepassword" component={ AdminChangePassword } />
         
          
          <Redirect to="/user-pages/login-1" />
        </Switch>
        <ToastContainer/>
      </Suspense>
    );
  }
}

export default AppRoutes;